import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import NProgress from 'nprogress'
import EmptyLayout from '../layouts/Empty.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import('@/views/404.vue'),
    meta: { title: 'Halaman tidak ditemukan !' },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { auth: true, title: 'Beranda' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login', layout: EmptyLayout },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/Logout.vue'),
    meta: { auth: true, layout: EmptyLayout },
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/master/Setting.vue'),
    meta: { auth: true, title: 'Setting Aplikasi' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    meta: { auth: true, title: 'User profile' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    meta: { auth: true, title: 'User profile' },
  },
  {
    path: '/pembelian/recommendation',
    name: 'PurchaseRecommendation',
    component: () => import('@/views/pembelian/PurchaseRecommendation.vue'),
    meta: { auth: true, title: 'Recommendation Pembelian' },
  },
  {
    path: '/pembelian/order',
    name: 'PurchaseOrder',
    component: () => import('@/views/pembelian/PurchaseOrder.vue'),
    meta: { auth: true, title: 'Order Pembelian' },
  },
  {
    path: '/pembelian/order/add',
    name: 'PurchaseOrderAdd',
    component: () => import('@/views/pembelian/PurchaseOrderDetail.vue'),
    meta: { auth: true, title: 'Order Pembelian Detail' },
  },
  {
    path: '/pembelian/order/edit/:id',
    name: 'PurchaseOrderEdit',
    component: () => import('@/views/pembelian/PurchaseOrderDetail.vue'),
    meta: { auth: true, title: 'Order Pembelian Detail' },
  },
  {
    path: '/pembelian/receive',
    name: 'PurchaseReceive',
    component: () => import('@/views/pembelian/PurchaseReceive.vue'),
    meta: { auth: true, title: 'Penerimaan' },
  },
  {
    path: '/pembelian/receive/add',
    name: 'PurchaseReceiveAdd',
    component: () => import('@/views/pembelian/PurchaseReceiveDetail.vue'),
    meta: { auth: true, title: 'Penerimaan Detail' },
  },
  {
    path: '/pembelian/receive/edit/:id',
    name: 'PurchaseReceiveEdit',
    component: () => import('@/views/pembelian/PurchaseReceiveDetail.vue'),
    meta: { auth: true, title: 'Penerimaan Detail' },
  },
  {
    path: '/pembelian/price-changes',
    name: 'PurchasePriceChanges',
    component: () => import('@/views/pembelian/PurchasePriceChanges.vue'),
    meta: { auth: true, title: 'Perubahan Harga' },
  },
  {
    path: '/pembelian/price-changes/edit/:id',
    name: 'PurchasePriceChangesEdit',
    component: () => import('@/views/pembelian/PurchasePriceChangesDetail.vue'),
    meta: { auth: true, title: 'Perubahan Harga Detail' },
  },

  {
    path: '/pembelian/retur',
    name: 'PurchaseRetur',
    component: () => import('@/views/pembelian/PurchaseRetur.vue'),
    meta: { auth: true, title: 'Retur' },
  },
  {
    path: '/pembelian/retur/add',
    name: 'PurchaseReturAdd',
    component: () => import('@/views/pembelian/PurchaseReturDetail.vue'),
    meta: { auth: true, title: 'Retur Detail' },
  },
  {
    path: '/pembelian/retur/edit/:id',
    name: 'PurchaseReturEdit',
    component: () => import('@/views/pembelian/PurchaseReturDetail.vue'),
    meta: { auth: true, title: 'Retur Detail' },
  },
  {
    path: '/penjualan/order',
    name: 'SaleingOrder',
    component: () => import('@/views/penjualan/SaleingOrder.vue'),
    meta: { auth: true, title: 'Order Penjualan' },
  },

  {
    path: '/master/identitas-perusahaan',
    name: 'IdentitasPerusahaan',
    component: () => import('@/views/master/IdentitasPerusahaan.vue'),
    meta: { auth: true, title: 'Identitas Perusahaan' },
  },
  {
    path: '/inventory/stock',
    name: 'Lihat Stok',
    component: () => import('@/views/inventory/Stock.vue'),
    meta: { auth: true, title: 'Lihat Stok' },
  },
  {
    path: '/inventory/transaction',
    name: 'Lihat Transaksi',
    component: () => import('@/views/inventory/Transaction.vue'),
    meta: { auth: true, title: 'Lihat Transaksi' },
  },
  {
    path: '/inventory/stock-adjustment',
    name: 'StockAdjustment',
    component: () => import('@/views/inventory/Adjustment.vue'),
    meta: { auth: true, title: 'Stock Adjustment' },
  },
  {
    path: '/inventory/adjustment/add',
    name: 'StockAdjustmentAdd',
    component: () => import('@/views/inventory/AdjustmentDetail.vue'),
    meta: { auth: true, title: 'Stock Adjustment Detail' },
  },
  {
    path: '/inventory/adjustment/edit/:id',
    name: 'StockAdjustmentEdit',
    component: () => import('@/views/inventory/AdjustmentDetail.vue'),
    meta: { auth: true, title: 'Stock Adjustment Detail' },
  },
  {
    path: '/inventory/stock-opname',
    name: 'StockOpname',
    component: () => import('@/views/inventory/Opname.vue'),
    meta: { auth: true, title: 'Stock Opname' },
  },
  {
    path: '/inventory/stock-opname/add',
    name: 'StockOpnameAdd',
    component: () => import('@/views/inventory/OpnameDetail.vue'),
    meta: { auth: true, title: 'Stock Opname Detail' },
  },
  {
    path: '/inventory/stock-opname/edit/:id',
    name: 'StockOpnameEdit',
    component: () => import('@/views/inventory/OpnameDetail.vue'),
    meta: { auth: true, title: 'Stock Opname Detail' },
  },
  {
    path: '/inventory/label-barcode',
    name: 'PrintBarcode',
    component: () => import('@/views/inventory/PrintBarcode.vue'),
    meta: { auth: true, title: 'Print Label Barcode' },
  },
  {
    path: '/master/divisi',
    name: 'Divisi',
    component: () => import('@/views/master/Divisi.vue'),
    meta: { auth: true, title: 'Divisi' },
  },
  {
    path: '/master/departemen',
    name: 'Departemen',
    component: () => import('@/views/master/Departemen.vue'),
    meta: { auth: true, title: 'Departemen' },
  },
  {
    path: '/master/sub-departemen',
    name: 'SubDepartemen',
    component: () => import('@/views/master/SubDepartemen.vue'),
    meta: { auth: true, title: 'SubDepartemen' },
  },
  {
    path: '/master/kategori',
    name: 'Kategori',
    component: () => import('@/views/master/Kategori.vue'),
    meta: { auth: true, title: 'Kategori' },
  },
  {
    path: '/master/item',
    name: 'Item',
    component: () => import('@/views/master/Item.vue'),
    meta: { auth: true, title: 'Item' },
  },
  {
    path: '/master/term',
    name: 'Termin',
    component: () => import('@/views/master/Term.vue'),
    meta: { auth: true, title: 'Termin' },
  },
  {
    path: '/master/supplier',
    name: 'Supplier',
    component: () => import('@/views/master/Supplier.vue'),
    meta: { auth: true, title: 'Supplier' },
  },
  {
    path: '/master/itemsupplier',
    name: 'ItemSupplier',
    component: () => import('@/views/master/ItemSupplier.vue'),
    meta: { auth: true, title: 'Item Supplier' },
  },
  {
    path: '/master/satuan',
    name: 'Satuan',
    component: () => import('@/views/master/Satuan.vue'),
    meta: { auth: true, title: 'Satuan' },
  },
  {
    path: '/master/user',
    name: 'User',
    component: () => import('@/views/master/User.vue'),
    meta: { auth: true, title: 'User' },
  },
  {
    path: '/master/role-permission',
    name: 'Role Permission',
    component: () => import('@/views/master/RolePermission.vue'),
    meta: { auth: true, title: 'Role Permission' },
  },
  {
    path: '/laporan/pembelian',
    name: 'LaporanPembelian',
    component: () => import('@/views/laporan/Pembelian.vue'),
    meta: { auth: true, title: 'Laporan Pembelian' },
  },
  {
    path: '/laporan/inventory',
    name: 'LaporanStock',
    component: () => import('@/views/laporan/Stock.vue'),
    meta: { auth: true, title: 'Laporan Inventory' },
  },
  {
    path: '/laporan/penjualan',
    name: 'LaporanPenjualan',
    component: () => import('@/views/laporan/Penjualan.vue'),
    meta: { auth: true, title: 'Laporan Penjualan' },
  },
  {
    path: '/laporan/hutang',
    name: 'LaporaHutang',
    component: () => import('@/views/laporan/Hutang.vue'),
    meta: { auth: true, title: 'Laporan Hutang Usaha' },
  },
  {
    path: '/promo',
    name: 'Promo',
    component: () => import('@/views/promo/Promo.vue'),
    meta: { auth: true, title: 'Promo' },
  },
  {
    path: '/promo/add',
    name: 'PromoAdd',
    component: () => import('@/views/promo/PromoDetail.vue'),
    meta: { auth: true, title: 'Promo Detail' },
  },
  {
    path: '/promo/edit/:id',
    name: 'PromoEdit',
    component: () => import('@/views/promo/PromoDetail.vue'),
    meta: { auth: true, title: 'Promo Detail' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  window.document.title = to.meta.title + ' - ' + process.env.VUE_APP_APP_NAME
  const auth = useAuthStore()

  if (to.matched.some((item) => item.meta.auth)) {
    if (!auth.isAuthenticated) {
      next({ path: '/login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (to.path) {
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
