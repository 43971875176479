import axios from 'axios'
import NProgress from 'nprogress'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('aid')}`,
  },
})

axiosInstance.interceptors.request.use(
  function (config) {
    NProgress.start()
    const token = localStorage.getItem('aid')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  function (error) {
    // TODO: Do something with request error
    NProgress.done()
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  function (response) {
    NProgress.done()
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.status === 401) {
      // store.dispatch('logout')
      // router.push('/login')
      window.location = '/login'
    }
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    NProgress.done()
    return Promise.reject(error)
  }
)

export default axiosInstance
