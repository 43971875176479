import http from '../clients/Axios'

export default class ProfileService {
  // Identitas Perusahaan
  async get() {
    return await http.get('/user/profile')
  }

  async update(form) {
    const res = await http.put(`/user/profile`, form)
    return res
  }

  // Get identitas perusahaan
  async getCompanyProfile() {
    return await http.get('/profile')
  }

  // Update identitas perusahaan
  async updateCompanyProfile(form) {
    return await http.put('/profile', form)
  }

  // // Update identitas perusahaan
  // async deleteUserProfile(id) {
  //   return await http.delete(`/profile/${id}`)
  // }
}
