import { Workbox } from 'workbox-window'
let workbox

if ('serviceWorker' in navigator) {
  workbox = new Workbox(`${process.env.BASE_URL}service-worker.js`)

  workbox.addEventListener('controlling', () => {
    window.location.reload()
  })

  workbox.addEventListener('installed', (event) => {
    if (event.isUpdate) {
      // eslint-disable-next-line no-console
      console.log('Update available.')
    }
  })

  workbox.register()
} else {
  workbox = null
}

export default workbox
