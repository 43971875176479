<template>
  <div>
    <Menubar :model="menuItems" class="fadeinup animation-duration-300">
      <template #start>
        <img alt="logo" src="/img/logo.png" height="40" class="mr-3" />
      </template>
      <template #end>
        <Button
          v-if="can('open_setting')"
          type="button"
          icon="pi pi-cog"
          class="p-button-text bg-transparent"
          style="color: black"
          @click="$router.push('/setting')"
        />
        <Button
          type="button"
          :label="userName"
          icon="pi pi-user"
          class="p-button-text bg-transparent"
          style="color: black"
          @click="onToggle"
        />
        <Menu ref="menu" :model="menuUser" :popup="true" />
      </template>
    </Menubar>
    <OverlayPanel
      ref="op"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '300px' }"
    >
      <ul class="p-0 mx-0 mt-0 list-none">
        <li class="flex align-items-center py-2">
          <template v-if="isMessageEmpty">
            <div
              class="
                flex
                align-items-center
                justify-content-center
                border-circle
                mr-3
                flex-shrink-0
              "
            >
              <i class="pi pi-info-circle text-blue-500"></i>
            </div>
            <span class="text-700 line-height-3">Tidak ada notifikasi.</span>
          </template>
          <template v-else>
            <ul style="width: 100%; list-style-type: none" class="p-0 m-0">
              <li
                :key="item"
                v-for="item in messages.slice().reverse()"
                class="pl-2 pr-2 m-1"
                :style="{
                  border: '1px solid #dddddd',
                  backgroundColor: item.reads === 0 ? '#eeeeee' : 'white',
                }"
                @click="readMessage(item)"
              >
                <p class="font-bold" style="line-height: 10px">
                  {{ item.title }}
                </p>
                <p>{{ item.body }}</p>
              </li>
            </ul>
          </template>
        </li>
      </ul>
      <Button
        label="Hapus notifikasi"
        class="w-full p-button-outlined p-button-secondary p-button-sm"
        :disabled="isMessageEmpty"
        @click="clearMessage"
      />
    </OverlayPanel>
    <Dialog
      v-model:visible="dialogLogout"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
      closeable
    >
      <template #header>
        <div class="font-bold text-xl">
          <i class="pi pi-sign-out mr-2"></i> Logout
        </div>
      </template>
      Keluar dari aplikasi ?
      <template #footer>
        <Button
          label="Batal"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
          @click="dialogLogout = false"
        />
        <Button
          label="Logout"
          icon="pi pi-sign-out"
          autofocus
          @click="$router.push('/logout')"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from 'vue'
import Menubar from 'primevue/menubar'
import { useAuthStore } from '@/store/auth'
import { useAbility } from '@casl/vue'

const { can } = useAbility()
const dialogLogout = ref(false)
const auth = useAuthStore()
const menu = ref()
const op = ref()

const menuItems = ref([
  {
    label: 'Home',
    icon: 'pi pi-th-large',
    to: '/',
  },
  {
    label: 'Pembelian',
    icon: 'pi pi-clone',
    visible() {
      return (
        can('open_rekomendasi_pembelian') ||
        can('open_pembelian') ||
        can('open_penerimaan') ||
        can('open_perubahan_harga') ||
        can('open_kontrabon') ||
        can('open_retur')
      )
    },
    items: [
      {
        label: 'Rekomendasi Pembelian',
        to: '/pembelian/recommendation',
        visible() {
          return can('open_rekomendasi_pembelian')
        },
      },
      {
        label: 'Order Pembelian',
        to: '/pembelian/order',
        visible() {
          return can('open_pembelian')
        },
      },
      {
        label: 'Penerimaan',
        to: '/pembelian/receive',
        visible() {
          return can('open_penerimaan')
        },
      },
      {
        label: 'Perubahan Harga',
        to: '/pembelian/price-changes',
        visible() {
          return can('open_perubahan_harga')
        },
      },
      {
        label: 'Kontra Bon',
        // url: 'http://192.168.6.163:84/',
        url: 'https://kontra-borma.infonetglobal.tech',
        target: '_blank',
        visible() {
          return can('open_kontrabon')
        },
      },
      {
        separator: true,
      },
      {
        label: 'Retur Pembelian',
        to: '/pembelian/retur',
        visible() {
          return can('open_retur')
        },
      },
    ],
  },
  {
    label: 'Penjualan',
    icon: 'pi pi-money-bill',
    visible() {
      return can('open_penjualan')
    },
    items: [
      {
        label: 'Order Penjualan',
        to: '/penjualan/order',
      },
      {
        label: 'Promo Penjualan',
        to: '/promo',
        visible() {
          return can('open_promo')
        },
      },
    ],
  },
  {
    label: 'Inventory',
    icon: 'pi pi-box',
    visible() {
      return (
        can('open_inventory_stock') ||
        can('open_inventory_transaction') ||
        can('open_inventory_adjustment') ||
        can('open_inventory_stock_opname') ||
        can('open_inventory_label_barcode')
      )
    },
    items: [
      {
        label: 'Lihat Stok',
        to: '/inventory/stock',
        visible() {
          return can('open_inventory_stock')
        },
      },
      {
        label: 'Lihat Transaksi',
        to: '/inventory/transaction',
        visible() {
          return can('open_inventory_transaction')
        },
      },
      {
        label: 'Stock Adjustment',
        to: '/inventory/stock-adjustment',
        visible() {
          return can('open_inventory_adjustment')
        },
      },
      {
        label: 'Stock Opname',
        to: '/inventory/stock-opname',
        visible() {
          return can('open_inventory_stock_opname')
        },
      },
      {
        separator: true,
      },
      {
        label: 'Print Label Barcode',
        to: '/inventory/label-barcode',
        visible() {
          return can('open_inventory_label_barcode')
        },
      },
    ],
  },
  {
    label: 'Master',
    icon: 'pi pi-folder',
    visible() {
      return (
        can('open_identitas_perusahaan') ||
        can('open_divisi') ||
        can('open_departemen') ||
        can('open_sub_departemen') ||
        can('open_kategori') ||
        can('open_item') ||
        can('open_termin') ||
        can('open_supplier') ||
        can('open_satuan') ||
        can('open_user')
      )
    },
    items: [
      {
        label: 'Identitas Perusahaan',
        to: '/master/identitas-perusahaan',
        visible() {
          return can('open_identitas_perusahaan')
        },
      },
      {
        label: 'Divisi',
        to: '/master/divisi',
        visible() {
          return can('open_divisi')
        },
      },
      {
        label: 'Departemen',
        to: '/master/departemen',
        visible() {
          return can('open_departemen')
        },
      },
      {
        label: 'Sub Departemen',
        to: '/master/sub-departemen',
        visible() {
          return can('open_sub_departemen')
        },
      },
      {
        label: 'Kategori',
        to: '/master/kategori',
        visible() {
          return can('open_kategori')
        },
      },
      {
        label: 'Item',
        to: '/master/item',
        visible() {
          return can('open_item')
        },
      },
      {
        separator: true,
      },
      {
        label: 'Termin',
        to: '/master/term',
        visible() {
          return can('open_termin')
        },
      },
      {
        label: 'Supplier',
        items: [
          {
            label: 'Data Supplier',
            to: '/master/supplier',
          },
          {
            label: 'Item Supplier',
            to: '/master/itemsupplier',
          },
        ],
        visible() {
          return can('open_supplier')
        },
      },
      {
        label: 'Satuan',
        to: '/master/satuan',
        visible() {
          return can('open_satuan')
        },
      },
      {
        label: 'User Management',
        items: [
          {
            label: 'Data User',
            to: '/master/user',
          },
          {
            label: 'Role Permission',
            to: '/master/role-permission',
          },
        ],
        visible() {
          return can('open_user')
        },
      },
    ],
  },
  {
    label: 'Laporan',
    icon: 'pi pi-file',
    visible() {
      return (
        can('open_laporan_pembelian') ||
        can('open_laporan_inventory') ||
        can('open_laporan_penjualan') ||
        can('open_laporan_hutang')
      )
    },
    items: [
      {
        label: 'Inventory',
        to: '/laporan/inventory',
        visible() {
          return can('open_laporan_inventory')
        },
      },
      {
        label: 'Pembelian',
        to: '/laporan/pembelian',
        visible() {
          return can('open_laporan_pembelian')
        },
      },
      {
        label: 'Penjualan',
        to: '/laporan/penjualan',
        visible() {
          return can('open_laporan_penjualan')
        },
      },
      {
        label: 'Hutang Usaha',
        to: '/laporan/hutang',
        visible() {
          return can('open_laporan_hutang')
        },
      },
    ],
  },
  // {
  //   label: 'Promo',
  //   icon: 'pi pi-tags',
  //   to: '/promo',
  //   visible() {
  //     return can('open_promo')
  //   },
  // },
])
const menuUser = ref([
  {
    label: 'Profile',
    icon: 'pi pi-user-edit',
    to: '/profile',
  },
  {
    label: 'Logout',
    icon: 'pi pi-sign-out',
    command: () => {
      logout()
    },
  },
])

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['reads', 'clear'])

const userName = computed(() => {
  return auth.user ? auth.user.name : ''
})

function onToggle(event) {
  menu.value.toggle(event)
}

function logout() {
  dialogLogout.value = true
}

function readMessage(item) {
  const reads = item.reads
  if (!reads) {
    item.reads = 1
    emit('reads', item)
  }
}

function clearMessage() {
  emit('clear')
}
</script>

<style scoped>
.p-menubar {
  /* background: #fccb33; */
  border: none;
  border-bottom: 2px solid #fccb33;
  border-radius: 0;
}
</style>
