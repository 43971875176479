import { defineStore } from 'pinia'
import http from '@/clients/Axios'
import { useStorage } from '@vueuse/core'
import defineAbilitiesFor from '@/services/rules'
import { isEmail, isNumber } from '@/helpers'

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    user: useStorage('user', {}),
    userData: {},
    permissions: [],
  }),
  getters: {
    isAuthenticated() {
      return Object.keys(this.user).length > 0
    },
  },
  actions: {
    async login(login, password, ability) {
      let loginForm = null

      if (isNumber(login)) {
        loginForm = {
          phone: login,
          password: password,
        }
      } else if (isEmail(login)) {
        loginForm = {
          email: login,
          password: password,
        }
      } else {
        loginForm = {
          username: login,
          password: password,
        }
      }
      await http.post('/user/login', loginForm).then(async (res) => {
        if (res.data.status === 1) {
          this.user = {
            id: res.data.data.id,
            name: res.data.data.name,
            roles: res.data.data.roles,
          }
          localStorage.setItem('aid', res.data.data.token)

          // Clear permissions array before adding new permissions
          this.permissions = []

          this.user.roles.forEach((role) => {
            this.permissions.push(
              ...role.permissions.map((permission) => permission.name)
            )
          })

          ability.update(defineAbilitiesFor(this.permissions))
        }
      })
    },
    async refreshPermission(ability) {
      // Clear permissions array before adding new permissions
      this.permissions = []

      this.user.roles.forEach((role) => {
        this.permissions.push(
          ...role.permissions.map((permission) => permission.name)
        )
      })

      if (this.user && this.permissions.length > 0) {
        ability.update(defineAbilitiesFor(this.permissions))
      }
    },
    async redirectLogout() {
      await http.post('/user/logout').then(async (res) => {
        if (res.data.status === 1) {
          localStorage.clear()
          this.user = {}
        }
      })
    },
    logout() {
      localStorage.clear()
      this.user = {}
    },
  },
})
