<template>
  <component :is="$route.meta.layout || 'div'">
    <Toast />
    <nav-bar v-if="auth.isAuthenticated" :messages="messages" />
    <router-view class="m-4" />
    <div class="text-xs ml-4 mb-4">
      <span class="uppercase">{{ appName }} {{ appVersion }} </span>
      <br />
      <span class="uppercase">CABANG {{ appCompany }}</span>
    </div>
    <Dialog
      :header="headerUpdate"
      v-model:visible="refreshDialog"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :modal="true"
      :closable="false"
    >
      <p class="m-0">
        Update aplikasi telah tersedia. Klik tombol Refresh untuk memperbaharui.
      </p>
      <template #footer>
        <Button
          label="Refresh"
          icon="pi pi-check"
          @click="updateApp"
          autofocus
        />
      </template>
    </Dialog>
    <ScrollTop />
  </component>
</template>

<script>
import ScrollTop from 'primevue/scrolltop'
import NavBar from './components/NavBar.vue'
import { useAuthStore } from '@/store/auth'
import CabangService from '@/services/CabangService'
import SettingService from '@/services/SettingService'
import ProfileService from '@/services/ProfileService'

export default {
  components: { NavBar, ScrollTop },
  inject: ['workbox', 'ability'],
  created() {
    this.settingService = new SettingService()
    this.cabangService = new CabangService()
    this.profileService = new ProfileService()
    this.auth = useAuthStore()
    if (this.workbox) {
      this.workbox.addEventListener('waiting', () => {
        this.refreshDialog = true
      })
    }
  },
  data() {
    return {
      default_cabang: [],
      cabang: null,
      cabangService: null,
      refreshDialog: false,
      messages: [],
      auth: {},
      appName: process.env.VUE_APP_APP_NAME,
      appVersion: process.env.VUE_APP_VERSION,
      dbVersion: '',
      appCompany: '',
    }
  },
  mounted() {
    if (this.auth.isAuthenticated) {
      this.settingService.get().then((res) => {
        this.default_cabang = res.data[1]
      })

      this.profileService.getCompanyProfile().then((res) => {
        this.appCompany = res.data.data.name
      })

      const messages = JSON.parse(localStorage.getItem('messages'))
      if (messages) {
        this.messages = messages
      }

      this.auth.refreshPermission(this.ability)
    }
  },
  computed: {
    headerUpdate() {
      //  return 'Update ' + this.appVersion + ' telah tersedia';
      return 'Update telah tersedia'
    },
  },
  methods: {
    async updateApp() {
      this.refreshDialog = false
      await this.workbox.messageSW({ type: 'SKIP_WAITING' })
    },
  },
}
</script>
